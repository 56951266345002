import React, { useContext, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { useLocation } from "react-router-dom";
import axios from "axios";
import "./home.css";
import Categories from "../components/Categories";
import { AuthContext } from "../context/authContext";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import alertify from "alertifyjs";

const Single = () => {
  const [posts, setPosts] = useState([]);
  const location = useLocation();
  const [likedByCurrentUser, setLikedByCurrentUser] = useState(false);
  const { currentUser } = useContext(AuthContext);
  const postId = location.pathname.split("/")[2];
  const records = posts.slice();

  // kategori filtrelemesi
  useEffect(() => {
    const fetchData = async () => {
      console.log("use effect home çalı??tı");
      try {
        console.log("postId", postId);
        const res = await axios.get(
          `https://hasanucar.net/rest-test/${postId}`
        );
        console.log("res", res.data);
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [postId]);

  const getText = (htmlString) => {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.innerHTML;
  };

  return (
    <div>
      <Navbar /> <div></div>
      <div className="container posts">
        {records.map((post) => (
          <div className="postalign-silinecek" key={post.id}>
            {" "}
            <div className="postalign-silinecek">
              <div className="postImageHome">
                <img
                  className="image-fluid"
                  src={`https://hasanucar.net/${post.field_image}`}
                  alt="postImg"
                ></img>
              </div>

              <div className="titledesc-silinecek">

                <div className="homePostTitle">
                    <h1 className="postTitle">
                      {post.title}
                    </h1>
                </div>

              	<div className="date">
                  {new Date(post.date).toLocaleDateString("tr-TR")} / {post.uid}
                </div>

                <div className="homePostDesc"
          			dangerouslySetInnerHTML={{ __html: getText(post.description) }}
        		/>
                <div className="dateLikeSection">
                </div>
              </div>
            </div>
     
          </div>
        ))}
      </div>
    </div>
  );
};

export default Single;

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
} from "react-router-dom";
import "./App.css";
import Home from "./pages/Home";
import Login from "./pages/Login";
import Register from "./pages/Register";
import Write from "./pages/Write";
import Single from "./pages/Single";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";

function App() {
  const queryClient = new QueryClient()
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
        <Router>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/uye-ol" element={<Register />} />
            <Route path="/post-olustur" element={<Write />} />
            <Route path="/post/:id" element={<Single />} />
            <Route path="/user/login" component={Login} />
          </Routes>
        </Router>
      </div>
    </QueryClientProvider>
  );
}

export default App;

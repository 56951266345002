import React from 'react'
import "./styles.css"

const Footer = () => {
  return (
    <div><footer className="footer" id="footer">
    <p>Footer</p>
  </footer></div>
  )
}

export default Footer
import React, { useContext } from "react";
import "./styles.css";
import { Link, useNavigate } from "react-router-dom";
import { AuthContext } from "../context/authContext";

const Navbar = () => {
  const navigate = useNavigate();
  const { currentUser, logout } = useContext(AuthContext);
  function logOut() {
    logout();
    navigate("/");
  }
  return (
    <div>
      {" "}
      <nav className="navbar navbar-expand-lg navbar-lght">
        <div className="container-fluid">
          <button
            className="navbar-toggler"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarTogglerDemo03"
            aria-controls="navbarTogglerDemo03"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <a className="navbar-brand" href="/">
            Anasayfa
          </a>
          <div
            className="collapse navbar-collapse d-none"
            id="navbarTogglerDemo03"
            data-toggle="collapse"
            data-target=".navbar-collapse"
          >
            <ul className="navbar-nav mb-2 mb-lg-0 d-none">
              {!currentUser && (
                <li className="nav-item log">
                  <Link to="/uye-ol">Üye Ol</Link>
                </li>
              )}
              {currentUser?.id === 2 && (
                <li className="nav-item log">
                  <Link to="/post-olustur">Post oluştur</Link>
                </li>
              )}
              {currentUser ? (
                <li className="nav-item log">
                  <Link onClick={ logOut } >Çıkış Yap</Link>
                </li>
              ) : (
                <li className="nav-item log">
                  <Link to="/giris-yap">Giriş Yap</Link>
                </li>
              )}
              <li>
                <span>{currentUser?.fullName ?? ""}</span>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;

import React from "react";
import { Link } from "react-router-dom";

const Categories = () => {
  return (
    <div className="categories">
      <ul className="nav justify-content-center">
        <li className="nav-item">
          <Link to="/">Tüm Postlar</Link>
        </li>
        <li className="nav-item">
          <Link to="/?category=Mutfak-Banyo">Mutfak-Banyo</Link>
        </li>
        <li className="nav-item">
          <Link to="/?category=Bahçe">Bahçe</Link>
        </li>
        <li className="nav-item">
          <Link to="/?category=Sürdürülebilirlik">Sürdürülebilirlik</Link>
        </li>
        <li className="nav-item">
          <Link to="/?category=Dekorasyon İp uçları">Dekorasyon İp uçları</Link>
        </li>
        <li className="nav-item">
          <Link to="/?category=Didim'e Özel">Didim'e Özel</Link>
        </li>
      </ul>
    </div>
  );
};

export default Categories;

import React, { useContext, useEffect, useState } from "react";
import Navbar from "../components/Navbar";
import Footer from "../components/Footer";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import "./home.css";
import Categories from "../components/Categories";
import { AuthContext } from "../context/authContext";
import {
  QueryClient,
  QueryClientProvider,
  useMutation,
  useQuery,
  useQueryClient,
} from "react-query";
import alertify from "alertifyjs";

const Home = () => {
  const [posts, setPosts] = useState([]);
  const location = useLocation();
  const [likedByCurrentUser, setLikedByCurrentUser] = useState(false);
  const category = useLocation().search;
  const { currentUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const postId = location.pathname.split("/")[2];
  const [cuurentPage , setCurrentPage] = useState(1)
  const postsPerPage = 4;
  const lastIndex = cuurentPage* postsPerPage;
  const firstIndex = lastIndex - postsPerPage;
  const records = posts.slice(firstIndex , lastIndex);
  const nPage = Math.ceil(posts.length / postsPerPage);
  const numbers = [...Array(nPage + 1).keys()].slice(1)

  // kategori filtrelemesi
  useEffect(() => {
    const fetchData = async () => {
      console.log("use effect home çalıştı");
      try {
        console.log("category", category);
        const res = await axios.get(
          `https://hasanucar.net/rest-test/${category}`
        );
        // console.log("res", res.data);
        setPosts(res.data);
      } catch (err) {
        console.log(err);
      }
    };

    fetchData();
  }, [category]);

  // metin kısaltma
  const excerpt = (str, count) => {
    if (str && str.length > count) {
      str = str.substring(0, count) + "...";
    }
    return str;
  };
  const getText = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    return doc.body.textContent;
  };

  const queryClient = useQueryClient();

function prePage(){
  if(cuurentPage == 1){
    setCurrentPage(cuurentPage)
  } else if(cuurentPage !== firstIndex){
    setCurrentPage(cuurentPage - 1)
  }
}
function nextPage(){
  if(cuurentPage !== nPage){
    setCurrentPage(cuurentPage + 1)
  }
}
function changePage(id){
  setCurrentPage(id)
}

 

  return (
    <div>
      <Navbar /> <div></div>
      <div className="container posts">
        {records.map((post) => (
          <div className="homepost" key={post.id}>
            {" "}
            <div className="postalign">
              <div className="postImageHome">
                <img
                  className="image"
                  src={`https://hasanucar.net/${post.field_image}`}
                  alt="postImg"
                ></img>
              </div>

              <div className="titledesc">
                {" "}
                <p className="homecategory d-none">  {post.category}  </p>

                <div className="homePostTitle">
                  <Link to={`/post/${post.id}`}>
                    <h1 className="postTitle">
                      {post.title}
                    </h1>
                  </Link>
                </div>
                <div className="homePostDesc">
                  <p className="postDesc">  {excerpt(getText(post.description), 320)}</p>
                </div>
                <div className="dateLikeSection">
                <div className="date">
                  {new Date(post.date).toLocaleDateString("tr-TR")} 
                    
                  {/* Şimdilik tek post yazarı sonradan değişir */}
                </div>{" "}
                {/* <div className="seeMoreBtn">
                <button className="btn ">
                  {" "}
                  <Link to={`/post/${post.id}`}>Daha Fazla</Link>{" "}
                </button>
              </div> */}
                </div>
              </div>
            </div>
     
          </div>
        ))}
      </div>
    
        <ul className="container pagination">
          <li className="page-item">
     		<a href="#" className="page-link" onClick={prePage}>Önceki</a>
          </li>
          {numbers.map((n , i)=>(
            <li className={`page-item ${cuurentPage == n ? "active" : ""}`} key={i}>
              <a href="#" className="page-link" onClick={()=>changePage(n)} > {n} </a>
            </li>
          ))}

          <li className="page-item">
              <a href="#" className="page-link" onClick={nextPage}>Sonraki</a>
          </li>
        </ul>
     
      <Footer />
    </div>
  );
};

export default Home;
